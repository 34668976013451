import React, { useEffect, useState } from 'react';
import './App.css';
import ExpenseList from "./components/ExpenseList";
import ExpenseForm from './components/ExpenseForm';
import Alert from './components/Alert';
const { v4: uuid } = require('uuid'); 

// const initialExpenses = [
//   {id: uuid(), charge:"bérlet", amount:1600},
//   {id: uuid(), charge:"autó", amount:1600},
//   {id: uuid(), charge:"hitel", amount:1600}
// ];
// console.log(initialExpenses);

const initialExpenses = localStorage.getItem("expenses")
? JSON.parse(localStorage.getItem("expenses"))
: [];

function App() {
  //**** state values *****/
  //all expenses, add expenses
  const [expenses, setExpenses] = useState(initialExpenses);

  //single expense
  const [charge, setCharge] = useState('');

   //single amount
   const [amount, setAmount] = useState('');

   //alert
   const [alert, setAlert] = useState({show:false});

   //edit
   const[edit, setEdit] = useState(false);

   //edit item
   const [id, setId] = useState(0);

    //**** useEffect *****/
    useEffect(() =>{
      console.log("we called useEffect");
      localStorage.setItem("expenses", JSON.stringify
      (expenses));

    },[expenses]);

   //**** Functionality *****/

   //handle charge
   const handleCharge = e =>{
      console.log(`charge: $(e.target.value);`);
      setCharge(e.target.value);
   };
   //handle amount
   const handleAmount = e =>{
    console.log(`amount: $(e.target.value);`);
    setAmount(e.target.value);
 };
 //handle alert
 const handleAlert = ({type, text}) =>{
    setAlert({show:true,type,text});
    setTimeout(()=>{
      setAlert({show:false})
    },3000);
 }

 //handle submit
 const handleSubmit = e =>{
  e.preventDefault();
  if(charge !== '' && amount > 0 ){
    if(edit){
      let tempExpenses = expenses.map(item => {
        return item.id === id?{...item,charge,amount} 
        :item;
      });
      setExpenses(tempExpenses);
      setEdit(false);
      handleAlert({type:"success", text:"tétel szerkesztve"});

    }
    else{
      const singleExpense = {id:uuid(),charge,amount};
      setExpenses([...expenses,singleExpense]);
      handleAlert({type:"success", text:"tétel hozzáadva"});
    }
   
    setCharge('');
    setAmount('');
  }
  else{
    //handle alert called
    handleAlert({
      tpye:"danger", 
      text:`költség értéke nem lehet üres és az összegnek nagyobbnak kell lenni,mint 0`
    });
  }
};
//clear all items
const clearItems = () =>{
  setExpenses([]);
  handleAlert({type:"danger", text:"összes tétel törölve" });
};
//handle delete
const handleDelete = (id) =>{
  let tempExpenses = expenses.filter(item=>item.id !== 
  id);
  setExpenses(tempExpenses);
  handleAlert({type:"danger", text:"tétel törölve" });
}
//handle edit
const handleEdit = (id) =>{
  let expense = expenses.find(item => item.id === id);
  let {charge,amount} = expense;
  setCharge(charge);
  setAmount(amount);
  setEdit(true);
  setId(id);
}

  return (
    <>
    {alert.show && <Alert type={alert.type} text=
    {alert.text}/>} 
   
    <h1>Kiadás kalkulátor</h1>
    <main className='App'>
      <ExpenseForm 
        charge={charge} 
        amount={amount}
        handleAmount={handleAmount} 
        handleCharge = {handleCharge}
        handleSubmit = {handleSubmit} 
        edit={edit}
      />
      <ExpenseList 
        expenses = {expenses} 
        handleDelete={handleDelete} 
        handleEdit={handleEdit} 
        clearItems={clearItems}      
      />
    </main>
    <h1>
      Összes kiadás:{" "}
        <span className='total'>
        {expenses.reduce((acc, curr)=>{
          return(acc += parseInt(curr.amount));
        },0)}  Ft
      </span>
    </h1>
   
   </>
  ) 
 
}

export default App;
