import React from "react";
import {MdSend} from "react-icons/md"

const ExpenseForm = ({
    charge, 
    amount, 
    handleCharge, 
    handleAmount, 
    handleSubmit,
    edit
    }) => {
    return <form onSubmit={handleSubmit}>
    <div className="form-center">
        <div className="form-group">
            <label htmlFor="charge">Költség</label>
            <input
                type="text"
                className="form-control"
                id="charge"
                name="charge"
                placeholder="pld. bérlet, számla"
                value={charge}
                onChange={handleCharge}
            />
        </div>
        <div className="form-group">
            <label htmlFor="amount">Összeg</label>
            <input
                type="number"
                className="form-control"
                id="amount"
                name="amount"
                placeholder="pld. 100.000"
                value={amount}
                onChange={handleAmount}
            />
        </div>        
    </div> 
    <button type="submit" className="btn">
       {edit ? "szerkesztés" : "rögzítés"}
        <MdSend className="btn-icon"/>
    </button>   
    </form>;       
}
export default ExpenseForm;